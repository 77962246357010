import React from 'react';
// import image1 from '../../assets/details/1.png';
import image3 from '../../assets/details/3.png';
import image4 from '../../assets/details/4.png';
import image2 from '../../assets/details/2.png';
import image5 from '../../assets/details/5.png';
import ReactImageMagnify from 'react-image-magnify';
import './detail.css';

function ProductImages({ProductImage,}) {
  return (
    <div className='scrollable-container col-md-8 d-flex'>
      <div>
        <div style={{width:'400px',height:'auto'}}>
        <ReactImageMagnify {...{
            smallImage: {
                alt: 'Wristwatch by Ted Baker London',
                isFluidWidth: true,
                src: ProductImage
            },
            largeImage: {
                src: ProductImage,
                width: 1200,
                height: 1000
            },
            enlargedImageStyle: {
              zIndex:999,
              position: 'relative',
            }
        }} />
        </div>

        <div style={{width:'360px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image2
              },
              largeImage: {
                  src: image2,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:999,
                position: 'relative',
              }
          }} />
        </div>
        <div style={{width:'360px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image2
              },
              largeImage: {
                  src: image2,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:999,
                position: 'relative',
              }
          }} />
        </div>
        <div style={{width:'360px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image2
              },
              largeImage: {
                  src: image2,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:999,
                position: 'relative',
              }
          }} />
        </div>
      </div>
      <div>
        <div style={{width:'300px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image3
              },
              largeImage: {
                  src: image3,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:1000,
                position: 'relative',
              }
          }} />
        </div>
        <div style={{width:'300px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image4
              },
              largeImage: {
                  src: image4,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:1000,
                position: 'relative',
              }
          }} />
        </div>
        <div style={{width:'300px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image5
              },
              largeImage: {
                  src: image5,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:1000,
                position: 'relative',
              }
          }} />
        </div>
        <div style={{width:'300px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image4
              },
              largeImage: {
                  src: image4,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:1000,
                position: 'relative',
                right:0
              }
          }} />
        </div>
        <div style={{width:'300px',height:'auto'}}>
          <ReactImageMagnify {...{
              smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: image5
              },
              largeImage: {
                  src: image5,
                  width: 1200,
                  height: 1000
              },
              enlargedImageStyle: {
                zIndex:1000,
                position: 'relative',
              }
          }} />
        </div>
      </div>
    </div>
  )
}

export default ProductImages
