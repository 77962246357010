import React from 'react'
import './Home.css';
import { useNavigate } from 'react-router-dom';

// topshowroom image import
import topShowImage01 from '../../assets/showroomimage01.png';
import topShowImage02 from '../../assets/showroomimage02.png';
import topShowImage03 from '../../assets/showroomimage03.png';
import topShowImage04 from '../../assets/showroomimage04.png';

const TopShowProductCartImage = ({image}) =>{
    return(
        <div style={{width: '150px',height: '150px',borderRadius:'15px'}} className="topshowroomProductCart bg-danger">
            <img style={{width: '100%',height: '100%',borderRadius:'15px',objectFit:'cover',objectPosition:'center'}} src={image} alt={image} />
        </div>
    );
}

const TopShowRoomProducts = () => {
  const navigate = useNavigate();
  return (
    <div className='container topshowroomproduct-container' id ="showroomproduct">
        <div className="row">
            <div className="col-md-4">
                <h2 className='topshowroom-heading-text'>Top HATIL products from our showroom.</h2>
                <p className='topshowroom-sub-text'>Discover the allure of our best-selling "Harmony Collection" 
                — a perfect blend of modern aesthetics and practical functionality.
                 Elevate your home with this versatile and timeless piece that complements 
                 any decor effortlessly.</p>
            </div>
            <div className="col-md-7 d-flex align-items-center topshowproductImageContainer">
                <div className='d-flex gap-xxl-4 gap-md-2 gap-2 ms-xxl-4 ms-0 align-items-center'>
                    <TopShowProductCartImage image={topShowImage01}/>
                    <TopShowProductCartImage image={topShowImage02}/>
                    <TopShowProductCartImage image={topShowImage03}/>
                    <TopShowProductCartImage image={topShowImage04}/>
                </div>
            </div>
            <div className='col-md-1 mt-md-0 mt-3 d-flex align-items-center justify-content-end'>
                    <button onClick={() => navigate("/ourCollections")} className='d-flex flex-column btn-showmore'>
                        <span className='moreItem-text'>More items</span>
                        <span id = "moreItem">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="8" viewBox="0 0 100 8" fill="none">
                                <path d="M99.3536 4.35355C99.5488 4.15829 99.5488 3.84171 99.3536 3.64645L96.1716 0.464466C95.9763 0.269204 95.6597 0.269204 95.4645 0.464466C95.2692 0.659728 95.2692 0.976311 95.4645 1.17157L98.2929 4L95.4645 6.82843C95.2692 7.02369 95.2692 7.34027 95.4645 7.53553C95.6597 7.7308 95.9763 7.7308 96.1716 7.53553L99.3536 4.35355ZM0 4.5H99V3.5H0V4.5Z" fill="#E71117"/>
                            </svg>
                        </span>
                    </button>
            </div>
        </div>
    </div>
  )
}

export default TopShowRoomProducts