import ProductImages from './ProductImages';
import ProductDescription from './ProductDescription';
import RequestItem from '../../components/RequestItem';
import { useEffect ,useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ProductDetail = () => {
  const allProducts = useSelector((state) => state.product.products);
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    // Find the product in the Redux store based on the ID
    const product = allProducts.find((product) => product.productId === parseInt(productId, 10));

    if (product) {
      setProductDetails(product);
    } else {
      // Handle the case where the product is not found
      console.error(`Product with ID ${productId} not found`);
    }
  }, [productId, allProducts]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className='ProductDetails'>
     <div className='container mt-5'>
        {productDetails.productName}
        <h6 style={{color:'gray'}}>
            {productDetails.productDescription}
        </h6>
      </div>
     <div className="my-5 container">
      <div className='row overflow-hidden'>
      <ProductImages
          ProductImage={productDetails.productImage}
          rightImage={productDetails.rightImage}
          leftImage={productDetails.leftImage}
        />        
        <ProductDescription price ={productDetails.price}/>
      </div>
     </div>

     <div className='py-5' style={{backgroundColor:'#F6F6F6'}}>
      <div className='container'>
        <p style={{fontSize:'14px',fontWeight:'bold'}}>Product Information</p>
        <ul>
          <li>Any spillage should be wiped dry with a soft cloth immediately as there is a chance of staining.</li>
          <li>Caring for fine furniture is much simpler than most people realize. In most cases, dusting regularly with a clean, soft, lint-free clo th is sufficient. </li>
          <li>We recommend using a slightly dampened cloth for cleaning wood. Be sure to dry the surface gently after cleaning to avoid water spotting.</li>
        </ul>
      </div>

      <div className='container'>
        <p style={{fontSize:'14px',fontWeight:'bold'}}>Warranty</p>
        <ul>
          <li>The product comes with a 12 months after sales service warranty (parts and labor) against any manufacturing defects and any other 
       issues with the materials that have been used.</li>
          <li>The warranty does not cover damages due to usage of the product beyond its intended use and wear & tear in the natural course of 
      product usage.</li>
        </ul>
      </div>
     </div>
    <RequestItem/>
    </div>
  )
}

export default ProductDetail;