import React, { useState, useRef, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import logo from '../assets/logo.png';
import './Header.css';
import SearchForm from './SearchForm';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchItem } from '../store';

const Header = ({ onSearchButtonClick }) => {

  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.search);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchButtonRef = useRef(null);
  const searchFormRef = useRef(null);

  useEffect(() => {
    console.log("search text in header ", searchText)
  }, [searchText]);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleClickOutside = (event) => {
    if (
      searchFormRef.current &&
      !searchFormRef.current.contains(event.target) &&
      searchButtonRef.current &&
      !searchButtonRef.current.contains(event.target)
    ) {
      // Clicked outside the search form and search button, so close the search form
      setIsSearchVisible(false);
    } else {
      console.log("the key press in the outside of the header")
    }
  };

  useEffect(() => {
    // Attach the click event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only once


  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-transparent">
        <div className="container">
          <NavLink className="navbar-brand" to="/" onClick={() => { dispatch(updateSearchItem('')) }}>
            <img height={50} className='img-fuild' src={logo} alt='logo'/>
          </NavLink>
          <button
            className={`navbar-toggler menu-button ${isOpen ? 'bg-white' : ''}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            {isOpen ? (
              // Close icon
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="7" fill="#F80000"/>
                <path d="M8 8L24 24M24 8L8 24" stroke="white" strokeWidth="3"/>
              </svg>
            ) : (
              // Hamburger icon
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="7" fill="#FFFFFF"/>
                <rect x="5" y="7" width="28" height="3" rx="1.5" fill="#F80000"/>
                <rect x="9" y="15" width="23" height="3" rx="1.5" fill="#F80000"/>
                <rect x="12" y="23" width="21" height="3" rx="1.5" fill="#F80000"/>
              </svg>
            )}
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav align-items-md-center ms-auto mb-2 mb-lg-0 gap-2">
              <li className={`nav-item ${isSearchVisible ? 'd-none' : ''}`}>
                <button type='button' className='nav-link' onClick={toggleSearch} ref={searchButtonRef}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                    <path fill="#2e2e2e" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                  </svg>
                </button>
              </li>
              {/* Search products*/}
              <li className={`nav-item ${isSearchVisible ? '' : 'd-none'}`}>
                <div className="nav-link" ref={searchFormRef}>
                  <SearchForm/>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/" onClick={() => { console.log("Hello from home"); dispatch(updateSearchItem('')); }}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/ourProjects" onClick={() => { dispatch(updateSearchItem('')) }}>Our Projects</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/ourCollections" onClick={() => { dispatch(updateSearchItem('')) }}>Our Collections</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/promotions" onClick={() => { dispatch(updateSearchItem('')) }}>Promotions</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/aboutUs" onClick={() => { dispatch(updateSearchItem('')) }}>About Us</NavLink>
              </li>
              <li className="nav-item">
                <Link className="nav-link contact-us ms-md-2" to="/contactUs" onClick={() => { dispatch(updateSearchItem('')) }}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header;
