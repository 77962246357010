import React from 'react';
import { motion} from 'framer-motion';
import RequestItem from '../../components/RequestItem';
import OurProjectProductCard from './OurProjectProductCard';
import './OurProject.css';
import { Helmet } from 'react-helmet';

// Importing images
import logo1 from '../../assets/ourproject/logo1.png';
import logo2 from '../../assets/ourproject/logo2.png';
import ourprojectImg1 from '../../assets/ourproject/ourprojectImg1.png';
import ourprojectImg2 from '../../assets/ourproject/ourprojectImg2.png';

const productDescription1 ='Dusit partnered with us in refurbishing their hotel lobby. We listened to their concept and deliver the luxurious aesthetics that suits their brand.';
const productDescription2 ='Le Meridien partnered with us in refurbishing their hotel rooms. We listened to their concept and deliver the luxurious aesthetics that suits their brand.'

const OurProject = () => {
  return (
    <div className='our-proeject-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>tshongkha-bhutan-our-project</title>
        <link rel="canonical" href="http://tshongkha.com/"/>
        <meta name="description" content="Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly. Keywords: tshongkha, tshongkha-bhutan, harmony collection, modern aesthetics, practical functionality, versatile, timeless, home decor, hatil showroom, kitchen goods, chair, table, bed" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "tshongkha-bhutan",
              "description": "Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Chorten Lam, 11001",
                "addressLocality": "Thimphu",
                "addressCountry": "Bhutan"
              },
              "telephone": "77792293",
              "image": "https://hatil-image.s3.ap-southeast-1.amazonaws.com/Nop_Image/HATIL%20Bed%20Epiphany-115.jpg",
              "url": "https://www.tshongkha.com/",
              "openingHours": "24/7",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "27.469999625780257",
                "longitude": "89.63866025973961"
              },
              "sameAs": [
                "https://www.facebook.com/HatilBhutan",
                "https://www.instagram.com/tshongkha_bhutan/"
              ]
            }
          `}
        </script>

      </Helmet>
      {/* <div className='our-project-hero-banner-container' style={{ height: '400px' }}>
        <div className="container h-100 d-flex flex-column justify-content-md-center justify-content">
              < motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.7, ease: 'easeInOut' }}
                className='ourproject-heading'>Our Projects</motion.h1>
              <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.6, ease: 'easeInOut', delay: 0.3 }}
              className='ourproject-sub-text w-50'>Make It Unique and Focus On The Benefit</motion.p>
          </div>
      </div> */}
      <div className="container welcome-section">
         <div className="row my-5">
            <div className="col-12">
              <h1 className='welcome-heading-text'>Welcome to our Projects Showcase! </h1>
            </div>
            <div className="col-12 mt-2">
              <p className='ourproject-text-dec'>
                Explore our success stories in our projects. From stylish corporate offices and inviting hospitality spaces to retail elegance and personalized residential retreats, each project reflects our commitment to excellence in furniture supply and interior design in Bhutan. Discover how we curate experiences, creating inspiring and innovative spaces across diverse sectors. Unveiling a glimpse of our transformative collaborations.
              </p>
            </div>
         </div>
      </div>
      <div className="container">
        <div className="row">
          <h1 className="welcome-heading-text">Our Projects</h1>
        </div>
        <div className="row mt-2 mb-5">
          <OurProjectProductCard backgroundImage={ourprojectImg1} logo={logo1} productDescription ={productDescription1} />
          <OurProjectProductCard backgroundImage={ourprojectImg2} logo={logo2} productDescription ={productDescription2}/>
          {/* <OurProjectProductCard backgroundImage={ourprojectImg2} logo={logo2} productDescription ={productDescription2}/>
          <OurProjectProductCard backgroundImage={ourprojectImg1} logo={logo1} productDescription ={productDescription1} /> */}
        </div>
      </div>
      <RequestItem/>
    </div>
  )
}

export default OurProject