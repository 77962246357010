import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';

import './Footer.css';
// Import icons
import PhoneIcon from '../assets/PhoneIcon.png';
import EmailIcon from '../assets/EmailIcon.png';
import LocationIcon from '../assets/LocationIcon.png';

const Footer = () => {
  const [state, handleSubmit] = useForm("xdoqazol");
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000); // Set the timeout for 2 seconds
    }
  }, [state.succeeded, handleSubmit]);

  const handleClick = () => {
    // Scroll the page to the top with a smooth effect
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <div className='footer-section mb-0'>
        <div className="container">
           <div className="row pt-5">
            <div className="col-md-3 d-flex flex-column gap-2">
              <h2 className='footer-heading'>Quick Links</h2>
              <Link className='footerItem' to='/aboutUs' onClick={handleClick}>About us</Link>
              <Link className='footerItem' to='/ourProjects' onClick={handleClick}>Our Projects</Link>
              <Link className='footerItem' to='/ourCollections' onClick={handleClick}>Our Collections</Link>
              <Link className='footerItem' to='/promotions' onClick={handleClick}>Promotions</Link>
            </div>
            <div className="col-md-4 mt-md-0 mt-4 d-flex flex-column gap-2">
              <h2 className='footer-heading'>Contact Us</h2>
              <div>
                <span><img src={LocationIcon} alt="Location icon" /></span>
                <span className='ms-3 footerItem'>4th floor, Big Mart building, Babena Thimphu</span>
              </div>
              <div>
                <span><img src={PhoneIcon} alt="Phone icon" /></span>
                <a href="tel:+97517986539" className="ms-3 footerItem">+975 1798 6539</a>
              </div>
              <div>
                <span><img src={EmailIcon} alt="Email icon" /></span>
                <a href="mailto:sonamtoeb@gmail.com" className="ms-3 footerItem">sonamtoeb@gmail.com</a>
              </div>
            </div>
            <div className="col-md-5 mt-md-0 mt-4 d-flex flex-column">
              <h2 className='footer-heading'>Don’t miss the latest update!</h2>
              <span className='subfooter-heading'>Sign up and get notified about new items and promotions.</span>
               <div className='getlatest-update-form mt-4'>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-auto">
                    <label htmlFor="email" className="visually-hidden">Email</label>
                    <input type="email" required className="form-control" name="email" id="Email" placeholder="Enter your email"/>
                    <ValidationError 
                      prefix="Email" 
                      field="email"
                      errors={state.errors}
                      style={{ color: 'red', fontSize: '14px', paddingTop:'10px' }}
                    />
                  </div>
                  <div class="col-auto">
                  <button type="submit" disabled={state.submitting} className="btn btn-danger footer-btn mb-3 px-4">
                    {state.submitting ? 'Sending...' : 'Send'}
                  </button>                  
                 </div>
                </form>
                {showSuccess &&
                  <h6 style={{color:'green'}}>Thank you for joining!</h6>
                }
               </div>
            </div>
           </div>
           <p className='text-center copyrightText mt-5 pb-3'>© 2023 All rights reserved Tshongkha Furniture and Furnishings</p>
        </div>
    </div>
  )
}

export default Footer