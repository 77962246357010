import React, { useRef, useEffect, useState } from 'react';
import './CatagoryNavbar.css';
import CategoryItem from './CategoryItem';
import New from '../assets/bed.png';
import Chair from '../assets/chair.png';
import Cabinets from '../assets/closet.png';
import Doors from '../assets/door.png';
import Kitchen from '../assets/kitchen.png';
import Office from '../assets/office-desk.png';
import Sofa from '../assets/sofa.png';

const CategoryNavBar = () => {
  const [activeCategory, setActiveCategory] = useState('New');
  const [showNextButton, setShowNextButton] = useState(false);
  const categoryContainerRef = useRef(null);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const handleCategoryScroll = () => {
    const container = categoryContainerRef.current;

    if (container) {
      const canScroll = container.scrollWidth > container.clientWidth;
      setShowNextButton(canScroll);
    }
  };

  const handleNextButtonClick = () => {
    const container = categoryContainerRef.current;

    if (container) {
      container.scrollLeft += 200; // Adjust the value based on your needs
    }
  };

  useEffect(() => {
    handleCategoryScroll();
    window.addEventListener('resize', handleCategoryScroll);

    return () => {
      window.removeEventListener('resize', handleCategoryScroll);
    };
  }, []);

  return (
    <div className='CategoryContainer d-flex justify-content-center align-items-center position-sticky top-0'>
      <div
        ref={categoryContainerRef}
        className="container d-flex justify-content-md-center justify-content-start align-items-center category-card-container"
        onScroll={handleCategoryScroll}
        style={{height: '100%'}}
      >
        <CategoryItem
          title="New"
          iconName={New}
          isNewArrival={true}
          isActive={activeCategory === 'New'}
          onClick={() => handleCategoryClick('New')}
        />
        <CategoryItem
          title="Chairs"
          iconName={Chair}
          isNewArrival={true}
          isActive={activeCategory === 'Chair'}
          onClick={() => handleCategoryClick('Chair')}
        />
        <CategoryItem
          title="Cabinets"
          iconName={Cabinets}
          isNewArrival={false}
          isActive={activeCategory === 'Cabinets'}
          onClick={() => handleCategoryClick('Cabinets')}
        />
        <CategoryItem
          title="Doors"
          iconName={Doors}
          isNewArrival={false}
          isActive={activeCategory === 'Doors'}
          onClick={() => handleCategoryClick('Doors')}
        />
        <CategoryItem
          title="Kitchen"
          iconName={Kitchen}
          isNewArrival={true}
          isActive={activeCategory === 'Kitchen'}
          onClick={() => handleCategoryClick('Kitchen')}
        />
        <CategoryItem
          title="Office"
          iconName={Office}
          sNewArrival={false}
          isActive={activeCategory === 'Office'}
          onClick={() => handleCategoryClick('Office')}
        />
        <CategoryItem
          title="Sofa"
          iconName={Sofa}
          isNewArrival={false}
          isActive={activeCategory === 'Sofa'}
          onClick={() => handleCategoryClick('Sofa')}
        />
      </div>
      {showNextButton && (
        <div className="slideNextCatagory">
          <button className='btn-next' onClick={handleNextButtonClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="30" width="40" viewBox="0 0 320 512">
              <path fill="#000000" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryNavBar;
