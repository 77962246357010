import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
    name:'category', 
    initialState: {
        category: 'New'
    },
    reducers: {
        updateCategory: (state,action) => {
            state.category = action.payload;
        }
    }
})

export const {updateCategory} = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;