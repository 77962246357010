import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OurProject.css';

const OurProjectProductCard = ({ backgroundImage,logo,productDescription }) => {
  const containerStyle = {
    background: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom', 
    height: '350px', 
    width: '100%',
    borderRadius: '10px',
  };
  const navigation = useNavigate();

  return (
    <div className='col-md-6 mt-4'>
        <div className='ourProjectcard' style={containerStyle}>
            <div className='cardtextContainer d-flex flex-wrap justify-content-between align-items-center mb-4 p-3'>
              <div>
                <img className='img-fluid' height={40} width={80} src={logo} alt="logo" />
              </div>
            </div>
        </div>
        <div className='mt-3 ms-3'>
          <p className='productDesc'>
            {productDescription}
          </p>
        </div>
    </div>
  );
};

export default OurProjectProductCard;
