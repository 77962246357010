import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name:'search',
    initialState: {
        searchItems:''
    },
    reducers: {
        updateSearchItem: (state,action) => {
            state.searchItems = action.payload;
        }
    }
})

export const {updateSearchItem} = searchSlice.actions;
export const searchReducer = searchSlice.reducer;