import React from 'react'
import './OurCollection.css'
import ProductCard from '../../components/ProductCard';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';



// productImage
import OurCollectionImg from '../../assets/collection/ourCollection.png';
import CollectionImg1 from '../../assets/collection/colletion1.png';
import CollectionImg2 from '../../assets/collection/collection2.png';
import newProductImage1 from '../../assets/newproduct1.png';
import newProductImage2 from '../../assets/newproduct2.png';
import newProductImage3 from '../../assets/newproduct3.png';
import newProductImage4 from '../../assets/newproduct4.png';
import newProductImage5 from '../../assets/newproduct5.png';
import newProductImage6 from '../../assets/newproduct6.png';
import RequestItem from './../../components/RequestItem';


const ProductData = [
    {
      productId : 1,
      productImage: newProductImage1,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    },
    {
      productId : 2,
      productImage: newProductImage2,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    },
    {
      productId : 2,
      productImage: newProductImage3,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    },
    {
      productId : 3,
      productImage: newProductImage4,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    },
    {
      productId:4,
      productImage: newProductImage5,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    },
    {
      productId:5,
      productImage: newProductImage6,
      productName: 'HATIL Dining Bradford-219 and Cardiz-219',
      productDescription: '6 Chairs | 1 Table with NTM Glass',
    }
  ];
const OurCollection = () => {
  const navigation = useNavigate();
  return (
    <div className='our-collection'>
        <Helmet>
        <meta charSet="utf-8" />
        <title>tshongkha-bhutan-our-collection</title>
        <link rel="canonical" href="http://tshongkha.com/"/>
        <meta name="description" content="Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly. Keywords: tshongkha, tshongkha-bhutan, harmony collection, modern aesthetics, practical functionality, versatile, timeless, home decor, hatil showroom, kitchen goods, chair, table, bed" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "tshongkha-bhutan",
              "description": "Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Chorten Lam, 11001",
                "addressLocality": "Thimphu",
                "addressCountry": "Bhutan"
              },
              "telephone": "77792293",
              "image": "https://hatil-image.s3.ap-southeast-1.amazonaws.com/Nop_Image/HATIL%20Bed%20Epiphany-115.jpg",
              "url": "https://www.tshongkha.com/",
              "openingHours": "24/7",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "27.469999625780257",
                "longitude": "89.63866025973961"
              },
              "sameAs": [
                "https://www.facebook.com/HatilBhutan",
                "https://www.instagram.com/tshongkha_bhutan/"
              ]
            }
          `}
        </script>

      </Helmet>
        <h1 className="heading text-center mt-4">Our Collections</h1>
        <div className="ourCollectionShow mt-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-3 mb-md-5">
                        <img className='our-collection-img' src={OurCollectionImg} alt="ourcollection" />
                    </div>
                    <div className="col-md-6 mt-3 mb-md-5">
                         <div className='ms-md-3 d-flex flex-column justify-content-evenly h-100'>
                            <div className='mt-md-5'>
                                <h6 className='collection-proudctname'>HATIL Sofa Vergil-318</h6>
                                <p className='collection-product-code'>HIF-201-318-UP-2-ST</p>
                            </div>
                            <div className='mt-2'>
                                <h6 className='product-hero-dec'>A sofa with beautiful and finished textureIt </h6>
                                <p className='product-detail'>
                                    A sofa with beautiful and finished textureIt 
                                    is manufactured with a standard structure but with a flair o f style and fashion by Hatil. The 
                                    formal design approach makes it a piece of furniture with immense contemporary elements.
                                    Suitable for any given space and style, with the convenience of seamlessly blending into every 
                                    possible requirement, you can blindly invest in this piece.
                                </p>
                            </div>
                            <div>
                                <button onClick={() => navigation(`/details/6`)} className='viewDetail-btn'>View Details</button>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className="row">
                        {ProductData.map((newProduct, index) => (
                            <ProductCard
                                key={index}
                                productId={newProduct.productId} 
                                productImage={newProduct.productImage}
                                productName={newProduct.productName}
                                productDescription={newProduct.productDescription}
                            />
                        ))}
            </div>
            <div className="row mb-5">
                    <div className="col-md-6 mt-4">
                        <div className='product-container'>
                            <img className='w-100 productImage' style={{height:'400px'}} src={CollectionImg1} alt="productImage" />
                            <div className='mt-3 container'>
                                <div className="row">
                                    <div className='col-md-8 ps-0'>
                                        <p className='product-name mb-0'>HATIL Dining Bradford-219 and Cardiz-219</p>
                                        <p className='product-detail m-1'>6 Chairs | 1 Table with NTM Glass</p>
                                    </div>
                                    <div className='col-md-4 ps-0 pe-0 mt-md-0 mt-2 d-flex justify-content-md-end align-items-center'>
                                        <button className='product-viewmore-detail-btn' onClick={() => navigation(`/details/4`)}
                                        >View Details</button>
                                    </div>
                                </div>
                            </div>  
                            </div>  
                        </div>
                    <div className="col-md-6 mt-4">
                        <div className='product-container'>
                            <img className='w-100 productImage' style={{height:'400px'}} src={CollectionImg2} alt="productImage" />
                            <div className='mt-3 container'>
                                <div className="row">
                                    <div className='col-md-8 ps-0'>
                                        <p className='product-name mb-0'>HATIL Dining Bradford-219 and Cardiz-219</p>
                                        <p className='product-detail m-1'>6 Chairs | 1 Table with NTM Glass</p>
                                    </div>
                                    <div className='col-md-4 ps-0 pe-0 mt-md-0 mt-2 d-flex justify-content-md-end align-items-center'>
                                        <button className='product-viewmore-detail-btn' onClick={() => navigation(`/details/4`)}
                                        >View Details</button>
                                    </div>
                                </div>
                            </div>  
                     </div>  
                </div>
            </div>
        </div>
        <RequestItem/>
    </div>
  )
}

export default OurCollection