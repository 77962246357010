import React, { useEffect, useState } from 'react';
import './SearchForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchItem } from '../store';

const SearchForm = () => {
  const dispatch = useDispatch();
  const searchItem = useSelector((state) => state.searchItem);

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    // dispatch(updateSearchItem(searchText));
  };

  const changeSearchItem = () => {
    dispatch(updateSearchItem(searchText));
  }

  useEffect(() => {
    changeSearchItem();
  },[searchText])

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     dispatch(updateSearchItem(searchText));
  //   }
  // };

  useEffect(() => {
    console.log("from search form ", searchText);
  }, [searchText]);

  return (
    <div className="searchInput-container">
      <input
        className="form-control search-input"
        type="text"
        name="search-product"
        placeholder="Search.."
        onChange={handleSearchChange}
        // onKeyPress={handleKeyPress}
        value={searchItem?.searchItems}
      />
      <svg
        id="svg-icon"
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="16"
        viewBox="0 0 512 512"
        onClick={() => {
          console.log("Hello from fucker")
        }}
      >
        <path
          fill="#696666"
          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
        />
      </svg>
    </div>
  );
};

export default SearchForm;
