import React, { useState } from 'react';
import { useForm } from '@formspree/react';



function ProductDescription({price}) {
  const [customerName, setCustomerName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [location, setLocation] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [state, handleSubmit] = useForm("xwkgpvkd");
  const isValidMobileNumber = (number) => {
    const phoneRegex = /^(77|17)\d{6}$/;
    return phoneRegex.test(number);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate mobile number
    if (!isValidMobileNumber(mobileNumber)) {
      setErrorMessage('Invalid mobile number.');
      return;
    }
    // Clear previous error messages
    setErrorMessage('');
    try {
      await handleSubmit(e);
      // Form submitted successfully, show success message
      setSuccessMessage('Thank you for ordering. We will be in touch soon.');
    } catch (error) {
      console.error('Form submission error:', error);
      // Handle form submission error
    }
  };
  return (
    <div className='col-md-4'>
      <p style={{fontSize:'14px',fontWeight:'bold'}}>PRODUCTS INCLUDE IN THIS SET</p>
      <div className='mt-4'>
        <p style={{fontSize:'16px'}}>Kingsport-106</p>
        <p style={{fontSize:'12px'}}>Color: Standard</p>
        <p style={{fontSize:'12px',marginTop:'-10px'}}>Qty: 1</p>
        <p style={{fontSize:'12px',marginTop:'-10px'}}>Size:  L600 X W410 X H400mm</p>
      </div>
      <div>
        <p style={{fontSize:'16px'}}>Prospero-110</p>
        <p style={{fontSize:'12px'}}>Color: Standard</p>
        <p style={{fontSize:'12px',marginTop:'-10px'}}>Qty: 1</p>
        <p style={{fontSize:'12px',marginTop:'-10px'}}>Size:  L600 X W410 X H400mm</p>
      </div>

      <hr style={{width:'70%',borderWidth:'2px'}}/>

      <div>
        <p style={{fontSize:'20px'}}><span style={{fontWeight:'bold'}}>Unit Price:</span>   Nu. {price}</p>
      </div>

      <hr style={{width:'70%',borderWidth:'2px'}}/>

      <div>
        <p style={{fontSize:'20px'}}>Colors Available</p>
        <div className='d-flex align-items-center'>
            <div style={{width:'40px',height:'40px',borderRadius:'50%',backgroundColor:"#3A3A3A"}}></div>
            <div className='ms-1' style={{width:'40px',height:'40px',borderRadius:'50%',backgroundColor:'#B43434'}}></div>
            <div className='ms-1' style={{width:'40px',height:'40px',borderRadius:'50%',backgroundColor:"#6AABB4"}}></div>
            <div className='ms-1' style={{width:'40px',height:'40px',borderRadius:'50%',backgroundColor:"#EBCD80"}}></div>
        </div>
      </div>
      <button type="button"  className="btn-place-order mt-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
         Place Order
      </button>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content modal-place-order">
            <div className="modal-header mt-2 border-0 d-flex justify-content-end">
              <button className="modal-btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
                  <rect width="32" height="32" rx="7" />
                  <path d="M8 8L24 24M24 8L8 24" stroke="white" strokeWidth="2" />
                </svg>
              </button>
            </div>
            <div className="modal-body px-md-5 text-center mb-5">
              {successMessage ? (
                <div>
                  <h1 className="modal-heading">Thank you for ordering</h1>
                  <p className="modal-sub-text">We will be in touch soon.</p>
                </div>
              ) : (
                <div>
                  <h1 className="modal-heading">Interested in this product?</h1>
                  <p className="modal-sub-text">You will receive a call or a message from our store manager to process your order.</p>
                  <div className="container">
                    <div className="row">
                      <form onSubmit={handleFormSubmit} className="mt-4">
                        <input
                          required
                          name="Customer Name"
                          placeholder="Your Name"
                          type="text"
                          pattern="[A-Za-z]{3,}"
                          title="Only alphabets OR a minimum of three alphabetical characters is required"
                          className="col-12 form-control"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      <input
                        required
                        name="Mobile Number"
                        placeholder="Mobile No."
                        type="number"
                        className={`col-12 mt-3 form-control ${errorMessage ? 'is-invalid' : ''}`}
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                      />
                      {errorMessage && (
                        <div className="invalid-feedback text-start" style={{ color: 'red' }}>
                          {errorMessage}
                        </div>
                      )}
                        <input
                          required
                          name="Location"
                          placeholder="Location"
                          type="text"
                          className="col-12 mt-3 form-control"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                        <button
                          type="submit"
                          style={{ background: '#E71117', fontWeight: '700' }}
                          className="col-12 mt-3 btn btn-danger"
                          disabled={state.submitting}
                        >
                          {state.submitting ? 'Submitting...' : 'Place Order Now'}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDescription
