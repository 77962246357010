// CategoryItem.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const CategoryItem = ({ title, iconName, isNewArrival, isActive, onClick }) => {
  const navigate = useNavigate();

  const handleCategoryClick = () => {
    // Assuming you want to navigate to a "ProductsPage" with the selected category
    navigate(`/productcategory/${title}`);
  };

  const handleClick = () => {
    handleCategoryClick();
    if (onClick) {
      onClick(); // Call the onClick prop if provided
    }
    window.scrollTo(0, 0);
  };

  return (
    <button
      className={`category-card px-4 ${isActive ? 'activeCategory' : ''}`}
      onClick={handleClick}
    >
      {isNewArrival && (
        <div className='newArrivalNotification'>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
            <circle cx="3.5" cy="3.5" r="3.5" fill="#FF0000"/>
          </svg>
        </div>
      )}
      <img className='mt-4 img-fluid' src={iconName} alt={iconName} />
      <p className='title-text'>{title}</p>
    </button>
  );
};

export default CategoryItem;
