import React, { useEffect, useState } from 'react';
import './SearchResult.css';
import RequestItem from '../../components/RequestItem';
import { useSelector } from 'react-redux';
import SearchProductCard from './SearchProductCard';
import emptybox from '../../assets/emptybox.png';

const SearchResult = () => {
  const allProduct = useSelector((state) => state.product);
  const searchText = useSelector((state) => state.search);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    console.log("all product from search page ", allProduct);
    setProducts(allProduct?.products);
  }, [allProduct]);

  useEffect(() => {
    if (allProduct && searchText && searchText.searchItems) {
      // Trim search text and filter products based on partial match in product name or category
      const trimmedSearchText = searchText.searchItems.trim().toLowerCase();
      const filteredProducts = allProduct.products.filter((product) =>
        product.productName.toLowerCase().includes(trimmedSearchText) ||
        product.category.toLowerCase().includes(trimmedSearchText)
      );

      setProducts(filteredProducts);
    }
  }, [allProduct, searchText]);

  useEffect(() => {
    console.log("search text ", searchText?.searchItems);
  }, [searchText]);

  console.log("search products ", products);

  return (
    <div className='search-container'>
      <div className="container">
        <div className="row my-4">
          <div className="col-6">
            <h4>Search Result</h4>
          </div>
        </div>
        {/* Search result */}
        {products.map((product) => (
          <SearchProductCard productImage={product.productImage} productName={product.productName} />
        ))}

          {products.length === 0 &&
            <div className='d-flex justify-content-center align-items-center py-2' style={{width: '100%'}}>
              <div>
                <img src={emptybox}/>
                <h6 className='mt-1' style={{fontWeight:'bold'}}>No Search Result Found!</h6>
              </div>
            </div>
          }
      </div>
      <RequestItem />
    </div>
  );
};

export default SearchResult;
