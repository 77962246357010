import { configureStore } from "@reduxjs/toolkit";
import { productReducer } from "./slices/productSlice";
import { searchReducer,updateSearchItem } from "./slices/searchSlice";
import { updateCategory,categoryReducer } from "./slices/categorySlice";

export const store = configureStore({
    reducer: {
        product:productReducer,
        search:searchReducer,
        category:categoryReducer
    }
})

export {updateSearchItem,updateCategory};