import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Importing Components
import HeroBanner from './HeroBanner';
import TopShowRoomProducts from './TopShowRoomProducts';
import NewArrivalProductHome from './NewArrivalProductHome';
import RequestItem from '../../components/RequestItem';
import {Helmet} from 'react-helmet';

const Home = () => {
    const Navigate = useNavigate();
    const newProductData = useSelector((state) => state.product.products);
    const NavigateToViewMoreProductPage = () =>{
      Navigate(`/productcategory/New`);
      window.scrollTo(0, 0);
    } 

  return (
    <main className='home-content'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>tshongkha-bhutan-home</title>
        <link rel="canonical" href="http://tshongkha.com/"/>
        <meta name="description" content="Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly. Keywords: tshongkha, tshongkha-bhutan, harmony collection, modern aesthetics, practical functionality, versatile, timeless, home decor, hatil showroom, kitchen goods, chair, table, bed" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "tshongkha-bhutan",
              "description": "Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Chorten Lam, 11001",
                "addressLocality": "Thimphu",
                "addressCountry": "Bhutan"
              },
              "telephone": "77792293",
              "image": "https://hatil-image.s3.ap-southeast-1.amazonaws.com/Nop_Image/HATIL%20Bed%20Epiphany-115.jpg",
              "url": "https://www.tshongkha.com/",
              "openingHours": "24/7",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "27.469999625780257",
                "longitude": "89.63866025973961"
              },
              "sameAs": [
                "https://www.facebook.com/HatilBhutan",
                "https://www.instagram.com/tshongkha_bhutan/"
              ]
            }
          `}
        </script>
      </Helmet>
      <HeroBanner/>
      <TopShowRoomProducts/>
      <div className='New-Arrival container' id = "allProduct">
          <h1 className='new-arrival-heading'>New arrivals</h1>
          <div className='row mb-4 d-flex flex-md-wrap flex-nowrap overflow-auto newproduct'>
            {newProductData.map((newProduct, index) => (
              <NewArrivalProductHome
                key={index}
                productId={newProduct.productId} 
                productImage={newProduct.productImage}
                productName={newProduct.productName}
                productDescription={newProduct.productDescription}
              />
            ))}
      </div>
      <div className='w-100 d-flex justify-content-md-center justify-content-end mb-5'>
              <button onClick={NavigateToViewMoreProductPage} className='d-flex flex-column btn-showmore'>
                  <span className='moreItem-text'>More items</span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="8" viewBox="0 0 100 8" fill="none">
                          <path d="M99.3536 4.35355C99.5488 4.15829 99.5488 3.84171 99.3536 3.64645L96.1716 0.464466C95.9763 0.269204 95.6597 0.269204 95.4645 0.464466C95.2692 0.659728 95.2692 0.976311 95.4645 1.17157L98.2929 4L95.4645 6.82843C95.2692 7.02369 95.2692 7.34027 95.4645 7.53553C95.6597 7.7308 95.9763 7.7308 96.1716 7.53553L99.3536 4.35355ZM0 4.5H99V3.5H0V4.5Z" fill="#E71117"/>
                        </svg>
                    </span>
              </button>
        </div>
      </div>
      <RequestItem/>

    </main>
  )
}

export default Home