import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductDetail from './Pages/ProductDetail/ProductDetail';
import CategoryNavBar from './components/CategoryNavBar';

// Importing pages
import Home from './Pages/Home/Home';
import About from './Pages/AboutUs/About';
import OurProject from './Pages/OurProject/OurProject';
import NewArrival from './Pages/NewArrival/NewArrival';
import OurCollection from './Pages/OurCollection/OurCollection';
import Contact from './Pages/ContactUs/Contact';
import Promotion from './Pages/Promotion/Promotion';
import SearchSuggestion from './components/SearchSuggestion';
import SearchResult from './Pages/searchResult/SearchResult';
import PageNotFound from './Pages/NotFound/PageNotFound';
import ProductCategory from './Pages/ProductCategory/ProductCategory';

import { useSelector,useDispatch } from 'react-redux';
import { updateSearchItem } from './store';

function App() {

  const dispatch = useDispatch();

  const searchItem = useSelector((state) => state.search);

  useEffect(() => {
    console.log("search text ", searchItem);
    console.log("is null ", searchItem.searchItems.length)
  },[searchItem])

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchSuggestionRef = useRef(null);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    console.log("toggling")
  };

  const handleClickOutside = (event) => {
    if (
      searchSuggestionRef.current &&
      !searchSuggestionRef.current.contains(event.target)
    ) {
      // Clicked outside the search suggestion, so close it
      setIsSearchVisible(false);
      dispatch(updateSearchItem(''))
      console.log("clicked outside ")
    }
  };

  useEffect(() => {
    // Attach the click event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
  <main className='min-vh-100'>
    <Router>
      <Header onSearchButtonClick={toggleSearch}/>
      {searchItem?.searchItems?.length > 0 ? (
        <SearchSuggestion ref={searchSuggestionRef} />
      ) : (
        <CategoryNavBar />
      )}
      <Routes>
        <Route path="/" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<Home/>} />
        <Route path="/aboutUs" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<About/>}/>
        <Route path="/ourProjects" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<OurProject/>}/>
        <Route path="/details/:productId" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<ProductDetail />} />
        <Route path="/newArrival" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<NewArrival/>}/>
        <Route path="/ourCollections" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<OurCollection/>}/>
        <Route path="/contactUs" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<Contact/>}/>
        <Route path="/promotions" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<Promotion/>}/>
        {/* <Route path="/searchResult" element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<SearchResult/>}/> */}
        <Route path='/productcategory/:category' element={searchItem?.searchItems?.length > 0 ? <SearchResult/>:<ProductCategory/>}/>
        <Route path='*' element={<PageNotFound/>}/>
        
      </Routes>
      <Footer/>
    </Router>
  </main>
  );
}

export default App;
