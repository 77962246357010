import React from 'react';
import pagenotfound from './404.png';

export default function PageNotFound() {
  return (
    <div className='min-vh-100'>
       <img src={pagenotfound} alt="page not found" className='img-fluid' style={{width:'100%',height:'400px',objectFit:'contain'}} />
    </div>
  )
}
