import React, { useEffect, useState } from 'react'
import './SearchSuggestion.css';
import { useDispatch,useSelector } from 'react-redux';
import { updateSearchItem } from '../store';

const SearchSuggestion = React.forwardRef(({props, ref}) => {

    const dispatch = useDispatch();

    const [sugestionText,setSuggestionText] = useState('');

    useEffect(() => {
        if (sugestionText !== '') {
            dispatch(updateSearchItem(sugestionText));
        }
        console.log("suggestion ",sugestionText);
    },[sugestionText])

  return (
    <div ref={ref} className='sugestion-container d-flex flex-column justify-content-center'>
        <div className="container py-4">
            <div>
                <h4 className='search-suggestion-heading'>Suggestions for you:</h4>
            </div>
            <div className='suggestion-items-conatainr ms-md-5 mt-3 d-flex gap-3 flex-wrap'>
                <button className='suggestion-items'
                    onClick={() => setSuggestionText('Tables')}
                >
                    Tables
                </button>
                <button className='suggestion-items'
                    onClick={() => setSuggestionText('Sofa')}
                >
                    Couch
                </button>
                <button className='suggestion-items'
                    onClick={() => setSuggestionText('Chair')}
                >
                    Chairs
                </button>
                <button className='suggestion-items'
                    onClick={() => setSuggestionText('Cloth Hanger')}
                >
                    Cloth Hangers
                </button>
            </div>
        </div>
    </div>
  )
})

export default SearchSuggestion