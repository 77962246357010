import React from 'react';
import './Promotion.css';
import { useNavigate } from 'react-router-dom';

const PromotionProductCard = ({ productImage, productName, productDescription}) => {

  const navigate = useNavigate();

  return (
    <div className="col-md-4 col-12 mt-4">
      <div className='product-container'>
        <img className='w-100 productImage' src={productImage} alt="productImage" />
        <div className='mt-3 container'>
          <div className="row">
            <div className='col-md-8 ps-0'>
              <p className='product-name mb-0'>{productName}</p>
              <p className='product-detail m-1'>{productDescription}</p>
            </div>
            <div className='col-md-4 ps-0 pe-0 mt-md-0 mt-2 d-flex justify-content-md-end align-items-center'>
              <button className='product-viewmore-detail-btn'
                onClick={() => navigate(`/details/1`)}>View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionProductCard;
