import React from 'react'
import './Promotion.css';
import RequestItem from '../../components/RequestItem';

//importing images
import productimg1 from '../../assets/newproduct1.png';
import productimg2 from '../../assets/newproduct1.png';
import PromotionProductCard from './PromotionProductCard';
import { Helmet } from 'react-helmet';


const Promotion = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>tshongkha-bhutan-promotion</title>
        <link rel="canonical" href="http://tshongkha.com/"/>
        <meta name="description" content="Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly. Keywords: tshongkha, tshongkha-bhutan, harmony collection, modern aesthetics, practical functionality, versatile, timeless, home decor, hatil showroom, kitchen goods, chair, table, bed" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "tshongkha-bhutan",
              "description": "Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Chorten Lam, 11001",
                "addressLocality": "Thimphu",
                "addressCountry": "Bhutan"
              },
              "telephone": "77792293",
              "image": "https://hatil-image.s3.ap-southeast-1.amazonaws.com/Nop_Image/HATIL%20Bed%20Epiphany-115.jpg",
              "url": "https://www.tshongkha.com/",
              "openingHours": "24/7",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "27.469999625780257",
                "longitude": "89.63866025973961"
              },
              "sameAs": [
                "https://www.facebook.com/HatilBhutan",
                "https://www.instagram.com/tshongkha_bhutan/"
              ]
            }
          `}
        </script>

      </Helmet>
    <div className='container'>
        <h1 className='mt-4 promotion-heading'>Promotions</h1>
        <div className="row mb-5">
            <PromotionProductCard productImage={productimg1} productName='New Year Giveaway!' productDescription='January 1 - 30, 2024'/>
            <PromotionProductCard productImage={productimg2} productName='New Year Giveaway!' productDescription='January 1 - 30, 2024'/>
        </div>
    </div>
    <RequestItem/>
    </>
  )
}

export default Promotion