import React from 'react';
import './ProductCard.css';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({productId, productImage, productName, productDescription, newArrival = false }) => {

  const navigate = useNavigate();

  return (
    <div className="col-md-4 col-6 mt-4">
      <div className='product-container position-relative'>
        <img className='w-100 productImage' src={productImage} alt="productImage" />
        <div className='mt-3 container'>
          <div className="row">
            <div className='col-md-8 ps-0'>
              <p className='product-name mb-0'>{productName}</p>
              <p className='product-detail m-1'>{productDescription}</p>
            </div>
            <div className='col-md-4 ps-0 pe-0 mt-md-0 mt-2 d-flex justify-content-md-end align-items-center'>
              <button className='product-viewmore-detail-btn'
                onClick={() => navigate(`/details/${productId}`)}
              >View Details</button>
            </div>
          </div>
        </div>
        {newArrival && (
          <div className='NewArrival-Bandage position-absolute px-4 py-2 d-block'>
            New Arrival
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
