import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm} from '@formspree/react';
import emailjs from '@emailjs/browser';

import './RequestItem.css';

const RequestItem = () => {

  const form = useRef(null);

  const [itemCode, setItemCode] = useState('');
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [state, handleSubmit] = useForm("xbjnjnyo");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(77|17)\d{6}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();

    if (!isValidEmail(emailOrPhoneNumber) && !isValidPhoneNumber(emailOrPhoneNumber)) {
      setErrorMessage('Invalid email or phone number');
      return;
    }

    setErrorMessage('');

    try {
      await handleSubmit(e);

      emailjs
      .sendForm('service_jprlu8g', 'template_2ma5nni', form.current, {
        publicKey: '_tmOLvCXobmYyXaor',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      setSuccessMessage('Successfully submitted your request.');
      // Clear form values immediately upon successful submission
      setItemCode('');
      setEmailOrPhoneNumber('');
    } catch (error) {
      console.error('Form submission error:', error);
      setErrorMessage('Error submitting the form');
    }
  };

  useEffect(() => {
    // Clear success message after 3 seconds
    if (successMessage) {
      const timeoutId = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);

      // Clear the timeout when the component unmounts or when a new submission occurs
      return () => clearTimeout(timeoutId);
    }
  }, [successMessage]);

  return (
    <div className='send-request-section d-flex flex-column align-items-center'>
      <h1 className='text-center sendRequest-heading'>Can’t find your items?</h1>
      <p className='text-center sendRequest-subheading'>
        Browse more items directly from <Link to='https://hatil.com' target='_blank' style={{ color: '#E71117' }}>Hatil Website</Link> and we will order it for you. Just enter
        the code of the item and send it to us.
      </p>
      <form ref = {form} className='d-flex flex-column gap-3 justify-content-center align-items-center mt-2' onSubmit={handleSendRequest}>
        <input
          className="form-control sendrequest-input text-center"
          name='message'
          required
          type="text"
          placeholder="Item code"
          value={itemCode}
          onChange={(e) => setItemCode(e.target.value)}
        />
        <input
          className="form-control sendrequest-input text-center"
          name='from_email_or_number'
          required
          type="text"
          placeholder="Email or Mobile No."
          value={emailOrPhoneNumber}
          onChange={(e) => setEmailOrPhoneNumber(e.target.value)}
        />
        {errorMessage && <p className="error-message text-center" style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p className="success-message text-center" style={{ color: 'green' }}>{successMessage}</p>}
        <button className='sendrequest-btn mt-3' type="submit" disabled={state.submitting}>
          {state.submitting ? 'Submitting...' : 'Send request'}
        </button>
      </form>
    </div>
  );
};

export default RequestItem;
