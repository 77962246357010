import React from 'react'
import { motion} from 'framer-motion';
import { Helmet } from 'react-helmet';

import './About.css'

import Logo from '../../assets/logobag.png';
import RequestItem from './../../components/RequestItem';

const About = () => {
  return (
    <div className='Aboutus-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>tshongkha-bhutan-aboutus</title>
        <link rel="canonical" href="http://tshongkha.com/"/>
        <meta name="description" content="Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly. Keywords: tshongkha, tshongkha-bhutan, harmony collection, modern aesthetics, practical functionality, versatile, timeless, home decor, hatil showroom, kitchen goods, chair, table, bed" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "tshongkha-bhutan",
              "description": "Discover the allure of our best-selling Harmony Collection — a perfect blend of modern aesthetics and practical functionality. Elevate your home with this versatile and timeless piece that complements any decor effortlessly.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Chorten Lam, 11001",
                "addressLocality": "Thimphu",
                "addressCountry": "Bhutan"
              },
              "telephone": "77792293",
              "image": "https://hatil-image.s3.ap-southeast-1.amazonaws.com/Nop_Image/HATIL%20Bed%20Epiphany-115.jpg",
              "url": "https://www.tshongkha.com/",
              "openingHours": "24/7",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "27.469999625780257",
                "longitude": "89.63866025973961"
              },
              "sameAs": [
                "https://www.facebook.com/HatilBhutan",
                "https://www.instagram.com/tshongkha_bhutan/"
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="aboutus-herobanner-container" style={{height: '400px'}}>
          <div className="container h-100 d-flex flex-column justify-content-center">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.7, ease: 'easeInOut' }}
               className='about-us-heading'>About Us</motion.h1>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.6, ease: 'easeInOut', delay: 0.3 }}
              className='about-sub-text w-50'>The furniture that enhances the beauty of your home</motion.p>
          </div>
      </div>
      <div className="container">
          <div className="row mx-1 mx-md-0 my-5 ps-md-5 d-flex align-items-center">
              <div className="logo col-md-3">
                <img src={Logo} alt="logo" className='img-fluid' width={80} />
              </div>
              <div className='col-md-9 about-text mt-md-0'>
                Tshongkha Bhutan is a furniture Store located at Thimphu. 
                Tshongkha Bhutan is a parter with HATIL and HATIL is a fast-growing
                Global Furniture Brand with customers and connoisseurs around the globe. 
                The brand presents an impeccable range of wooden furniture products 
                manufactured from the best-sourced materials and with a deft touch of 
                seasoned artisanship.              
              </div>
          </div>
          <div className="row mx-1 mx-md-0 about-us-text-contain-container d-flex align-items-center p-0 mb-2">
             <div className="col-md-3">
                <p className='pt-3 pt-0 ps-md-5 about-heading-title'>Principle</p>
             </div>
             <div className="col-md-9">
               <p className='pt-md-3 pt-0 about-contain-text'>Commitment to excellence, sustainability, and customer satisfaction. We believe in creating value through innovation, responsible sourcing, and fostering relationships built on trust and integrity.</p>
             </div>
          </div>
          <div className="row mx-1 mx-md-0 about-us-text-contain-container d-flex align-items-center p-0 mb-2">
             <div className="col-md-3">
                <p className='pt-3 pt-0 ps-md-5 about-heading-title'>Vision</p>
             </div>
             <div className="col-md-9">
               <p className='pt-md-3 pt-0 about-contain-text'>To become the leading name in furniture by setting benchmarks in design, quality, and environmental friendly. We envision a world where every home reflects the perfect blend of aesthetics, functionality, and eco-consciousness</p>
             </div>
          </div>
          <div className="row mx-1 mx-md-0 about-us-text-contain-container d-flex align-items-center p-0 mb-5">
             <div className="col-md-3">
                <p className='pt-3 pt-0 ps-md-5 about-heading-title'>Mission</p>
             </div>
             <div className="col-md-9">
               <p className='pt-md-3 pt-0 about-contain-text'>To enrich lives by delivering exceptional furniture that combines style, comfort, and sustainability. We aim to transform spaces into homes with pieces that tell a story, crafted for longevity and inspired by innovation</p>
             </div>
          </div>
          <div className='row'>
           <p className='about-heading-title'>Location</p>
          </div>
          <div className='row ms-md-5 mb-5'>
            <iframe title='Belpi lam-5' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3538.9332360500475!2d89.63307987404414!3d27.50245173450126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e195cd2f10d0a5%3A0x43a8d95eee0a18f5!2sB%C3%A8lpi%20Lam-5%2C%20Thimphu!5e0!3m2!1sen!2sbt!4v1703428826906!5m2!1sen!2sbt" width="100%" height="450" style={{border:'0px',borderRadius:'20px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="row ms-md-5 mb-5">
            <div className="col-md-6 order-md-last order-first d-flex justify-content-md-end align-items-md-center pe-0 mb-md-0 mb-3">
            <a
              href="https://www.google.com/maps?q=27.469992808255014,89.63867025963722"
              target="_blank"
              rel="noopener noreferrer"
              className='viewOnmap-btn text-decoration-none d-flex justify-content-center align-items-center'
            > 
              View On Maps
            </a>
            </div>
          </div>
      </div>
      <RequestItem/>
    </div>
  )
}

export default About