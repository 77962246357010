import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSearchItem } from '../../store';

function SearchProductCard({productImage,productName}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

  return (
    <div className="row mb-4">
        <div className="col-md-6 mt-3 mb-md-5">
            <img className='our-collection-img' src={productImage} alt="ourcollection" />
        </div>
        <div className="col-md-6 mt-3 mb-md-5">
                <div className='ms-md-3 d-flex flex-column justify-content-evenly h-100'>
                <div className='mt-md-5'>
                    <h6 className='collection-proudctname'>{productName}</h6>
                    <p className='collection-product-code'>HIF-201-318-UP-2-ST</p>
                </div>
                <div className='mt-2'>
                    <h6 className='product-hero-dec'>A sofa with beautiful and finished textureIt </h6>
                    <p className='product-detail'>
                        A sofa with beautiful and finished textureIt 
                        is manufactured with a standard structure but with a flair o f style and fashion by Hatil. The 
                        formal design approach makes it a piece of furniture with immense contemporary elements.
                        Suitable for any given space and style, with the convenience of seamlessly blending into every 
                        possible requirement, you can blindly invest in this piece.
                    </p>
                </div>
                <div>
                    <button className='viewDetail-btn'
                        onClick={() => {
                            dispatch(updateSearchItem(''))
                            navigate("/details")
                        }}
                    >View Details</button>
                </div>
                </div>
        </div>
        </div>
  )
}

export default SearchProductCard
