import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Importing Components
import ProductCard from '../../components/ProductCard';
import RequestItem from '../../components/RequestItem';

const ProductCategory = () => {
  const { category } = useParams(); // Extracting category from the URL parameters
  const allProducts = useSelector((state) => state.product.products);

  // Filtering products based on the selected category
  const filteredProducts = category === 'New'
    ? allProducts  // Show all products when category is 'New'
    : allProducts.filter((product) => product.category === category);

  return (
    <div id="top">
      <div className='container'>
        <h1 className='arrival-heading'>
          {category === 'New' ? 'New Arrivals' : `All ${category} Collections`}
        </h1>
        {filteredProducts.length > 0 ? (
          <div className='row mb-4'>
            {filteredProducts.map((newProduct, index) => (
              <ProductCard
                key={index}
                productId={newProduct.productId} 
                productImage={newProduct.productImage}
                productName={newProduct.productName}
                productDescription={newProduct.productDescription}
                newArrival={category === 'New'}
              />
            ))}
          </div>
        ) : (
          <p className='text-center my-5'>No products available for the selected category.</p>
        )}
      </div>
      <RequestItem />
    </div>
  );
};

export default ProductCategory;
