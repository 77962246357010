import {createSlice} from '@reduxjs/toolkit';
import newProductImage1 from '../../assets/newproduct1.png';
import chairImage1 from '../product-images/HATIL Easy Chair Aster-111.jpg';
import wardromImage1 from '../product-images/HATIL Wardrobe Wells-101.png';
import DressingTable from '../product-images/HATIL Dressing Table Crown-152.png';
import Bed1 from '../product-images/HATIL Bed Desire-154.png';
import sofa1 from '../product-images/HATIL Sofa Tangelo-239.png';

import image3 from '../../assets/details/3.png';
import image4 from '../../assets/details/4.png';
import image2 from '../../assets/details/2.png';
import image5 from '../../assets/details/5.png';

const productSlice = createSlice({
    name:'product',
    initialState: {
        products: [{
            productId :1,
            productImage: newProductImage1,
            productName: 'HATIL Dining Platter-156 and Lime-128',
            category:'Kitchen',
            productDescription: '6 Chair | 1 Table with NTM Glass',
            description: 'Made from superior quality melamine faced particle board with international standard density and load bearing capacity with mild Steel Same color edge banding done in latest Homag line',
            price: 45000,
            rightImage: [],
            leftImage: []
          },
          {
            productId :2,
            productImage: wardromImage1,
            productName: 'HATIL Wardrobe Wells-101',
            category:'Cabinets',
            productDescription: 'Body | Frame set',
            description: 'Made from superior quality melamine faced particle board with international standard density and load bearing capacity with mild Steel Same color edge banding done in latest Homag line',
            price: 45000,
            rightImage: ['https://hatil.com/images/404-Image.png','https://hatil.com/sites/default/files/product_set_images/Rhone-175+177+178-(Body-&-Frame)_637845013139020299_Rhone-175177178-3.jpg'],
            leftImage: ['https://hatil.com/sites/default/files/product_set_images/Rhone-175+177+178-(Body-&-Frame)_637845013138864053_Rhone-175177178-1.jpg']
          },
          {
            productId :3,
            productImage: DressingTable,
            productName: 'HATIL Dressing Table Crown-152',
            category:'Office',
            productDescription: 'Carnation | 134',
            description:'Most of our furniture is made of natural components, which will have natural differences in grain construction and occasional minor blemish',
            price: 45000,
            rightImage: ['https://hatil.com/sites/default/files/product_set_images/637815822400787777_Carnation-134-2.jpg','https://hatil.com/sites/default/files/product_set_images/637815822400787777_Carnation-134-1.jpg'],
            leftImage: ['https://hatil.com/sites/default/files/product_set_images/637815822400787777_Carnation-134-1.jpg']
          },
          {
            productId :4,
            productImage: Bed1,
            productName: 'HATIL Bed Desire-154',
            category:'Bed',
            productDescription: 'Obsession | 151',
            description:'Made from Kiln-dried imported Beech wood, veneered engineered wood and veneered MDF.',
            price: 45000,
            rightImage: ['https://hatil.com/sites/default/files/product_set_images/637785368417694257_2.1.jpg','https://hatil.com/sites/default/files/product_set_images/637785368417538004_1.jpg'],
            leftImage: ['https://hatil.com/sites/default/files/product_set_images/637785368417694257_4.jpg']
          },
          {
            productId :5,
            productImage: chairImage1,
            productName: 'HATIL Easy Chair Aster-111',
            category:'Chairs',
            productDescription: 'Gordon | 128',
            price: 35000,
            description:'Made from Kiln-dried imported Beech wood and veneered engineered wood.',
            rightImage: [image2,image3,image4],
            leftImage: [image5]
          },
          {
            productId :6,
            productImage: sofa1,
            productName: 'HATIL Sofa Tangelo-239',
            category: 'Sofa',
            productDescription: '6 Chairs | 1 Table with NTM Glass',
            rightImage: ['https://hatil.com/sites/default/files/product_set_images/638105169778260982_1%20-Cardiff-253-HTL-201-253-10-2_8.jpg','https://hatil.com/sites/default/files/product_set_images/638105169778417216_1%20-Cardiff-253-HTL-201-253-10-2_11.jpg'],
            leftImage: ['https://hatil.com/sites/default/files/product_set_images/638105169778104723_1%20-Cardiff-253-HTL-201-253-10-2.jpg','https://hatil.com/sites/default/files/product_set_images/638105169778417216_1%20-Cardiff-253-HTL-201-253-10-2_10.jpg'],
            price: 45000,
            description:'This L-shaped will perfectly suit any living room corner because of its contemporary design. Spending a long time on this sofa will not give you back pain since our furniture is designed ergonomically. Plus, you will get four shelves in the middle of this sofa, and you may keep your necessary stuff there.'
          }
        ]
    }
})

export const productReducer = productSlice.reducer;