import React, { useEffect } from 'react'
import './NewArrival.css';

// Importing Components
import ProductCard from '../../components/ProductCard';
import RequestItem from '../../components/RequestItem';

// productImage
import newProductImage1 from '../../assets/newproduct1.png';
import newProductImage2 from '../../assets/newproduct2.png';
import newProductImage3 from '../../assets/newproduct3.png';
import newProductImage4 from '../../assets/newproduct4.png';
import newProductImage5 from '../../assets/newproduct5.png';
import newProductImage6 from '../../assets/newproduct6.png';
import newProductImage7 from '../../assets/newproduct7.png';
import newProductImage8 from '../../assets/newproduct8.png';
import newProductImage9 from '../../assets/newproduct9.png';
import newProductImage10 from '../../assets/newproduct10.png';
import newProductImage11 from '../../assets/newproduct11.png';
import newProductImage12 from '../../assets/newproduct12.png';

const newProductData = [
  {
    productImage: newProductImage1,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage2,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage3,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage4,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage5,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage6,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage7,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage8,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage9,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage10,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage11,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
  {
    productImage: newProductImage12,
    productName: 'HATIL Dining Bradford-219 and Cardiz-219',
    productDescription: '6 Chairs | 1 Table with NTM Glass',
  },
];

const NewArrival = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <div className='arrival-content'>
     <div className='New-Arrival container'>
        <h1 className='arrival-heading'>New arrivals</h1>
        <div className='row mb-4'>
        {newProductData.map((newProduct, index) => (
          <ProductCard
            key={index}
            productImage={newProduct.productImage}
            productName={newProduct.productName}
            productDescription={newProduct.productDescription}
            newArrival = {true}
          />
        ))}
        </div>
     </div>
    <RequestItem/>
    </div>
  )
}

export default NewArrival