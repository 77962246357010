import React, { useState, useEffect } from 'react';
import { motion,AnimatePresence} from 'framer-motion';
import { useNavigate } from 'react-router-dom';


import herobanner02 from '../../assets/herobanner02.png'; 
import herobanner03 from '../../assets/herobanner03.png'; 


import herobannercaptionLogo1 from '../../assets/herobanner.png';
import herobannercaptionLogo2 from '../../assets/ourproject/logo2.png';

const slides = [
  {
    image: herobanner02,
    title: "We provide elevated Home, Office and Hotel furnishings",
    herobannercaptionLogo:herobannercaptionLogo1,
    subTitle: "The project unveils Tsongkhang's intricate interior desgin patterns, creating a culturally immersive experience at Dusitd2 Yarkay.",
   
  },
  {
    image: herobanner03,
    title: "We provide elevated Home, Office and Hotel furnishings",
    herobannercaptionLogo:herobannercaptionLogo2,
    subTitle: "The project centred on the resort's interior with exquisite dining furniture from Tshongkha.",
  },
];

const HeroBanner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  const navigate = useNavigate();

  return (
    <motion.div className='herobannerContainer'
    style={{ maxWidth: '100%', overflowX: 'hidden' }}>
    <AnimatePresence mode='wait'>
      <motion.img
        key={currentSlide}
        className="w-100 heroImage"
        src={slides[currentSlide].image}
        alt={`slide${currentSlide + 1}`}
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 1}}
        transition={{ duration: 0.6, ease: 'easeInOut' }}
      />
 
      <div className='overlay'></div>
      <div className="slidercaption container">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.7, ease: 'easeInOut' }}
          className='caption-title'
          >
          {slides[currentSlide].title}
        </motion.h1>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.6, ease: 'easeInOut', delay: 0.8 }}
        className="slider-button-next container"
        >
          <div style={{ height: '100%' }} className="row">
            <div className="col-md-1 col-2 d-flex align-items-center">
              <img className='img-fluid' src={slides[currentSlide].herobannercaptionLogo} alt="herobanner" />
            </div>
            <div className="col-md-6 col-10 d-flex flex-row align-items-center">
              <p className='caption-sub-text mt-3 ps-md-1 ps-3'>{slides[currentSlide].subTitle}</p>
            </div>
            <div className="col-md-2 d-md-flex d-none align-items-center">
              <button  onClick={() => { navigate("/ourProjects"); window.scrollTo(0, 0); }} className='check-project-button'>Check our projects</button>
            </div>
            {/* Slider Indicator */}
            <div className="col-md-3 d-md-flex d-none gap-2 align-items-center justify-content-md-end">
              {slides.map((slide, index) => (
                <button
                  key={index}
                  className={`slidebuttonIndicator ${currentSlide === index ? 'activeIndicator' : ''}`}
                  onClick={() => handleIndicatorClick(index)}
                ></button>
              ))}
            </div>
          </div>
        </motion.div>
        {/* Small Screen Content */}
        <div className='row'>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6, ease: 'easeInOut', delay: 1 }} 
          className='mt-5 d-md-none d-block'>
            <button onClick={() => { navigate("/contactUs"); window.scrollTo(0, 0); }} className='check-project-button'>Contact us</button>
          </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.6, ease: 'easeInOut', delay: 1.2 }} className='mt-3 d-md-none d-block'>
            <button onClick={() => { navigate("/ourProjects"); window.scrollTo(0, 0); }} className='check-project-button'>Check our projects</button>
          </motion.div>
          <div className="col-md-12 d-md-none d-flex gap-2 align-items-center justify-content-center mt-4">
            {slides.map((slide, index) => (
              <button
                key={index}
                className={`slidebuttonIndicator ${currentSlide === index ? 'activeIndicator' : ''}`}
                onClick={() => handleIndicatorClick(index)}
              ></button>
            ))}
          </div>
        </div>
      </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default HeroBanner;
